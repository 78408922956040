import { Controller } from "@hotwired/stimulus"
import { eventListeners } from "@popperjs/core"

// Connects to data-controller="button-show-courses"
export default class extends Controller {


  connect() {
    console.log("hell")
  }


};
